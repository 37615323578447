var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm mới nhóm công lương",
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "120px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên nhóm", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên nhóm" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submitForm("dataInput")
                  },
                },
                model: {
                  value: _vm.dataInput.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "name", $$v)
                  },
                  expression: "dataInput.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "note" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập mô tả",
                },
                model: {
                  value: _vm.dataInput.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "note", $$v)
                  },
                  expression: "dataInput.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }